import { connectAutoComplete } from 'react-instantsearch-dom'
import React, { useEffect, useState } from 'react'
import router from 'next/router'
import PropTypes from 'prop-types'
import DOMPurify from 'isomorphic-dompurify'
import Typography from '../../../components/Typography/Typography'
import Button from '../../../components/Button'
import twStyles from '../../../components/Header/Header.tw-styles'
import LoginStore from '../../../components/UserManagement/Login-store'
import { searchPlaceholder } from '../../../constants/texts'
import { triggerMainSearchEvent, triggerSearchEvent } from '../../../helpers/gaEvent.helper'
import styles from './AutoComplete.tw-styles'
import { standardEventForSearchLoad } from '../../AuthContainer/standardEventErrorLog'
import { STATUS_1212 } from '../../../constants/status-code'

const Autocomplete = ({ hits, refine, handleSearchIcon }) => {
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false)

  const handleSuggestions = (event) => {
    event.preventDefault()
    LoginStore.query = event.currentTarget.value
    if (LoginStore.query.trim() !== '') {
      refine(DOMPurify.sanitize(LoginStore.query))
      triggerSearchEvent(LoginStore.query)
      setShowSearchSuggestions(true)
    } else setShowSearchSuggestions(false)
  }

  const handleSearch = (event) => {
    event.preventDefault()
    if (LoginStore.query && LoginStore.query.trim() !== '') {
      triggerMainSearchEvent()
      router.push(`/search?t=${DOMPurify.sanitize(LoginStore.query)}`)
    }
  }

  useEffect(() => {
    if (hits?.length > 0 && LoginStore?.query !== null && showSearchSuggestions) {
      standardEventForSearchLoad(STATUS_1212)
    }
  }, [LoginStore.query])

  return (
    <>
      <form noValidate className='w-full surveysearch relative -top-3' aria-label='Search' action='' role='search' onSubmit={(e) => handleSearch(e)}>
        <label htmlFor='focus'>
          <input
            data-testid='auto-complete-searchbox'
            type='text'
            id='focus'
            className={styles.searchField}
            placeholder={searchPlaceholder}
            value={LoginStore.query}
            aria-label={`search ${LoginStore.query}`}
            onChange={(event) => {
              handleSearchIcon()
              handleSuggestions(event)
            }}
          />
        </label>
      </form>
      <div className={twStyles.searchDropdown}>
        {showSearchSuggestions && hits.slice(0, 5).map((hit) => (
          <Button
            key={hit.objectID}
            to={hit.link}
            className={twStyles.searchDropdownList}
          >
            <Typography markdown={hit.title} />
          </Button>

        ))}
      </div>
    </>
  )
}

Autocomplete.propTypes = {
  hits: PropTypes.oneOfType([PropTypes.array]),
  refine: PropTypes.func,
  handleSearchIcon: PropTypes.func,
}

Autocomplete.defaultProps = {
  hits: [],
  refine: () => {},
  handleSearchIcon: () => {},

}
export default connectAutoComplete(Autocomplete)
