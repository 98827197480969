import { makeAutoObservable } from 'mobx'

class LayoutStore {
  constructor() {
    makeAutoObservable(this)
  }

  brandsCollection = {}

  retailersCollection = {}

  showAnimation = ''

  init = (collection) => {
    this.brandsCollection = collection.find((brand) => brand.slug === 'brand')
    this.retailersCollection = collection.find(
      (brand) => brand.slug === 'retailer',
    )
  }
}

export default new LayoutStore()
