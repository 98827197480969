import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withRouter } from 'next/router'
import { oktaAuthentication } from '../../helpers/login.helper'
import LoginStore from '../UserManagement/Login-store'
import LinkButton from '../LinkButton/LinkButton'
import Icon from '../Icon/Icon'
import aimiaStore from '../../containers/AIMIA/aimia.store'
import { alertBoxText, oktaLogin, oktaSignup } from '../../constants/texts'
import Button from '../Button'
import staticRoutes from '../../../config/router.config'
import twStyles from './Header.tw-styles'
import {
  triggerMessageBarEvent,
  triggerAlertbarDonationsIconButtonEvent,
  triggerAlertbarPointsIconButtonEvent,
} from '../../helpers/gaEvent.helper'
import { alertBox } from '../../constants/page-source'
import { fetchPointsBalanceApi } from '../../helpers/api.helper'
import { storageAvailable } from '../../adapters/serverside.adapters'

const ALERT_DELAY = 5000
let APiCalled = false

const AlertBox = ({
  router, showAlertBarRightSideContents, alertBar, siteSettings,
}) => {
  const [alertIndex, setAlertIndex] = useState(0)
  const callCharityPointsAPI = async () => {
    const shouldRefreshPoints = new RegExp(`.*${staticRoutes.myImpactDashboard}/?$`).test(router?.asPath)
    await fetchPointsBalanceApi()
    await aimiaStore.fetchCharityLevelPoints(shouldRefreshPoints)
    // setFetched(true)
  }
  const clickHandler = () => {
    LoginStore.showCompleteProfileOverlay = true
    LoginStore.completeProfileCTALaunched = true
  }
  useEffect(() => {
    if (!LoginStore.userLoggedIn) {
      return () => {}
    }
    const name = LoginStore?.profileData?.name || null
    let userAlerts = alertBar?.map((alert, index) => {
      const newData = alert
      if (index === 0) {
        newData.linkText = alert?.linkText?.replace(
          '{user}',
          name?.toUpperCase(),
        )
      }
      return newData
    })
    if (LoginStore.buildYourProfileSurveyTaken) {
      userAlerts = userAlerts?.filter((alert) => alert?.name !== 'Build your profile')
    }
    if (LoginStore.completeProfile20SurveyTaken) {
      userAlerts = userAlerts?.filter((alert) => alert?.name !== 'complete-profile')
    }

    LoginStore.filteredAlerts = [...userAlerts]
    const noOfAlerts = (LoginStore?.filteredAlerts?.length || 0) - 1
    const timeout = setInterval(() => {
      setAlertIndex((currIndex) => (currIndex === noOfAlerts ? 1 : currIndex + 1))
    }, ALERT_DELAY)
    if (LoginStore.userLoggedIn && !APiCalled && showAlertBarRightSideContents) {
      callCharityPointsAPI()
      APiCalled = true
    }
    if (localStorage.getItem('helloAlertShown')) {
      setAlertIndex(1)
    } else {
      localStorage.setItem('helloAlertShown', 'true')
      setAlertIndex(0)
    }
    return () => clearTimeout(timeout)
  }, [LoginStore.profileData, LoginStore.rewardStatus, LoginStore.completeProfile20SurveyTaken,
    LoginStore.buildYourProfileSurveyTaken])
  return (
    <div
      className={twStyles.headerAlert.wrapper}
    >
      {LoginStore.userLoggedIn === true && (
        <div className={twStyles.alerbox.alertWrapper}>
          <div className='container mx-auto'>
            <div className={twStyles.headerAlert.loginWrapper}>
              <div className={showAlertBarRightSideContents
                ? twStyles.headerAlert.nameWrapperWithIcons : twStyles.headerAlert.nameWrapper}
              >
                { LoginStore
                 && LoginStore.filteredAlerts
                 && LoginStore.filteredAlerts.map((alert, index) => (alert
                 && alert.name === 'complete-profile'
                 && router?.asPath !== '/digital-coupons/'
                 && LoginStore.completeProfile20SurveyTaken === false ? (
                   <LinkButton
                     handler={() => clickHandler()}
                     className={alertIndex === index ? 'active' : 'hidden'}
                     key={alert && alert.sys && alert.sys.id}
                     {...alert}
                     pageSource={alertBox}
                     variant='alert-box-complete'
                     monthlyPromoRewardId={siteSettings?.monthlyPromoRewardId}
                     featuredSurvey={siteSettings?.featuredSurvey}
                     surveyContextualLoginText={siteSettings?.surveyContextualLoginText}
                     mosStartAndEndDate={siteSettings?.mosStartAndEndDate}
                     behaviour={alert?.behaviour}
                   />
                   ) : (alert && alert.name !== 'complete-profile'
                    && (
                    <LinkButton
                      className={alertIndex === index ? 'active' : 'hidden'}
                      pageSource={alertBox}
                      key={alert && alert.sys && alert.sys.id}
                      {...alert}
                      monthlyPromoRewardId={siteSettings?.monthlyPromoRewardId}
                      featuredSurvey={siteSettings?.featuredSurvey}
                      surveyContextualLoginText={siteSettings?.surveyContextualLoginText}
                      mosStartAndEndDate={siteSettings?.mosStartAndEndDate}
                      behaviour={alert?.behaviour}
                    />
                    )
                   )))}
              </div>
              {showAlertBarRightSideContents && (
              <div className={twStyles.headerAlert.pointsWrapper.pwrapper}>
                <div className={twStyles.headerAlert.pointsWrapper.wrapper}>
                  {typeof sessionStorage !== 'undefined'
                  && sessionStorage.getItem('unreadMessageCount') ? (
                    <>
                      <Button
                        to={staticRoutes.myImpactDashboard}
                        handler={() => triggerAlertbarPointsIconButtonEvent()}
                      >
                        <div
                          className={
                        twStyles.headerAlert.pointsWrapper.rewardPoints
                      }
                        >
                          {aimiaStore.totalPointsAvailable}
                          <Icon name='sparksMini' className='w-30' />
                        </div>
                      </Button>
                      <Button
                        to={staticRoutes.myImpactDashboard}
                        handler={() => triggerAlertbarDonationsIconButtonEvent()}
                      >
                        <div
                          className={
                        twStyles.headerAlert.pointsWrapper.rewardPoints
                      }
                        >
                          {aimiaStore.charityLevelPoints[LoginStore.charityID] || 0}
                          <Icon name='causeIcon' active={LoginStore.charityID} />
                        </div>
                      </Button>
                      <Button to={staticRoutes.myMessages} handler={() => triggerMessageBarEvent()}>
                        <div className={
                        twStyles.headerAlert.pointsWrapper.charityPoints
                      }
                        >
                          {Number.isNaN(typeof sessionStorage !== 'undefined'
                          && sessionStorage.getItem('unreadMessageCount')) ? 0 : sessionStorage.getItem('unreadMessageCount')}
                          <Icon name='notification' />
                        </div>
                      </Button>
                    </>
                    ) : (
                      <>
                        <Icon name='spinnerWhite' />
                      </>
                    )}
                </div>
              </div>
              )}
            </div>
          </div>
        </div>
      )}

      {LoginStore.userLoggedIn === false && (
        <div className={twStyles.headerAlert.logoutWrapper}>
          <div className='container mx-auto'>
            <Button
              handler={() => {
                if (storageAvailable('localStorage') && router.pathname === '/good-news/[slug]') {
                  localStorage.setItem('articlePendingUrl', LoginStore.articlePendingUrl)
                }
                const exactPath = window?.location?.href
                oktaAuthentication(oktaLogin, exactPath)
              }}
              className={twStyles.headerAlert.signInButton}
              aria-label={alertBoxText.signin}
            >
              {alertBoxText.signin}
            </Button>
            {alertBoxText.or}
            <Button
              handler={() => {
                const exactPath = window?.location?.href
                if (storageAvailable('localStorage')) {
                  localStorage.setItem('blueBarSignup', true)
                }
                oktaAuthentication(oktaSignup, exactPath)
              }}
              className='signup-button underline pl-05'
              aria-label={alertBoxText.signup}
            >
              {alertBoxText.signup}
            </Button>
          </div>
        </div>
      )}
      {LoginStore.userLoggedIn === undefined && (
        <div className='justify-end flex w-full pr-15 lg:pr-40'>
          <Icon name='spinnerWhite' className='w-16 h-16 lg:w-24 lg:h-24 ' />
        </div>
      )}
    </div>
  )
}

AlertBox.propTypes = {
  alertBar: PropTypes.instanceOf(Array),
  router: PropTypes.instanceOf(Object).isRequired,
  showAlertBarRightSideContents: PropTypes.bool,
  siteSettings: PropTypes.oneOfType([PropTypes.object]),
}

AlertBox.defaultProps = {
  alertBar: [],
  showAlertBarRightSideContents: false,
  siteSettings: null,
}
export default withRouter(observer(AlertBox))
