import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import { observer } from 'mobx-react'
import { ModernWebSearch } from '@mwrc/mw-search'
import DOMPurify from 'isomorphic-dompurify'
import AutoComplete from '../../containers/Search/SearchWidgets/AutoComplete'
import Button from '../Button'
import LinkButton from '../LinkButton/LinkButton'
import SignIn from '../UserManagement/SignIn'
import twStyles from './Header.tw-styles'
import styles from './Header.styles'
import Icon from '../Icon/Icon'
import getActiveStatus from '../../helpers/navigation.helper'
import LoginStore from '../UserManagement/Login-store'
import AimiaStore from '../../containers/AIMIA/aimia.store'
import { readMessages, postPromotionMessages } from '../../containers/UserManagement/MyMessages/MessageCenter.helper'
import { triggerLogoClickEvent, triggerCouponsEvent } from '../../helpers/gaEvent.helper'
import ModalV2 from '../Modal/ModalV2'
import staticRoutes from '../../../config/router.config'
import digitalCouponsStore from '../../containers/DigitalCoupons/digitalCoupons.store'
import { algoliaSearchConfig } from '../../adapters/algolia.adapter'
import { feedbackSurvey, getMasterSurveyID } from '../../helpers/segmanta.helper'
import { apiResponse, COUPONS24 } from '../../constants/digitalCouponConstants'
import { scrollToQuiz } from '../../helpers/url.helper'
import useScreenSize from '../UseScreenSize'
import { storageAvailable } from '../../adapters/serverside.adapters'
import { variants } from '../../constants/variants'

const Header = (props) => {
  const {
    primaryNavCollection,
    sideNavCollection,
    earnPointsSubNavigationCollection,
    doGoodSubNavigationCollection,
    oktaLabels,
    router,
    selectCause,
    confirmationModal,
    userRightMenuCollection,
    monthlyPromoRewardId,
    featuredSurveyRewardType,
    emailVerificationModalContent,
    cascadeSampleIdAddToBasketForStage,
    cascadeSampleIdAddToBasketForProd,
    campaignSamplesIdCollection,
    inActiveCampaigns,
    privacyText,
    featuredSurvey,
    surveyContextualLoginText,
    messageCollection,
    mosStartAndEndDate,
    role,
  } = props

  const { isDesktop } = useScreenSize()
  const [showSubMenu, setSubMenu] = useState(false)
  const [showEarnPointsSubMenu, setEarnPointsSubMenu] = useState(false)
  const [showDoGoodSubMenu, setDoGoodSubMenu] = useState(false)
  const [expandSearchBar, setSearchBar] = useState(false)
  const [searchHovered, setSearchHovered] = useState(false)
  const [closeHovered, setCloseHovered] = useState(false)
  const [earnPointsMoreMenu, setEarnPointsMoreMenu] = useState(false)
  const [doGoodMoreMenu, setDoGoodMoreMenu] = useState(false)
  const [showEarnPointsArrowIcon, setEarnPointsShowArrowIcon] = useState('arrowDownBlueIcon')
  const [showDoGoodArrowIcon, setDoGoodArrowIcon] = useState('arrowDownBlueIcon')
  const [showDotArrowIcon, setDotArrowIcon] = useState('arrowDownBlueIcon')
  const [searchIcon, setSearchIcon] = useState(false)
  const [closeIcon, setCloseIcon] = useState(false)
  const [showearnPointsArrowView, setshowearnPointsArrowView] = useState('arrowNavDown')
  const [showDoGoodArrowView, setshowDoGoodArrowView] = useState('arrowNavDown')

  const handleDelayNavigation = (button, delayTime) => {
    setTimeout(() => {
      if (button === 'Earn Points') {
        setEarnPointsShowArrowIcon('arrowUpBlueIcon')
        setDoGoodArrowIcon('arrowDownBlueIcon')
        setDotArrowIcon('arrowDownBlueIcon')
        setEarnPointsSubMenu(true)
        setDoGoodSubMenu(false)
        setSubMenu(false)
      }
      if (button === 'Do Good') {
        setDoGoodArrowIcon('arrowUpBlueIcon')
        setEarnPointsShowArrowIcon('arrowDownBlueIcon')
        setDotArrowIcon('arrowDownBlueIcon')
        setEarnPointsSubMenu(false)
        setDoGoodSubMenu(true)
        setSubMenu(false)
      }
    }, delayTime)
  }

  const handleSubNavigation = (button) => {
    if ((/iPad/i.test(navigator.userAgent))) {
      handleDelayNavigation(button, 0)
    } else {
      handleDelayNavigation(button, 500)
    }
  }

  const handleHambSubNavigation = (button) => {
    if (button === 'Earn Points') {
      const arrowValue = showEarnPointsArrowIcon === 'arrowUpBlueIcon' ? 'arrowDownBlueIcon' : 'arrowUpBlueIcon'
      const earnPointsClassName = showearnPointsArrowView === 'arrowNavDown' ? 'arrowNavUp' : 'arrowNavDown'
      setshowearnPointsArrowView(earnPointsClassName)
      setshowDoGoodArrowView('arrowNavDown')
      setEarnPointsShowArrowIcon(arrowValue)
      setDoGoodArrowIcon('arrowDownBlueIcon')
      setEarnPointsMoreMenu(!earnPointsMoreMenu)
      setDoGoodMoreMenu(false)
    }
    if (button === 'Do Good') {
      const arrowValue = showDoGoodArrowIcon === 'arrowUpBlueIcon' ? 'arrowDownBlueIcon' : 'arrowUpBlueIcon'
      const doGoodClassName = showDoGoodArrowView === 'arrowNavDown' ? 'arrowNavUp' : 'arrowNavDown'
      setshowDoGoodArrowView(doGoodClassName)
      setshowearnPointsArrowView('arrowNavDown')
      setDoGoodArrowIcon(arrowValue)
      setEarnPointsShowArrowIcon('arrowDownBlueIcon')
      setEarnPointsMoreMenu(false)
      setDoGoodMoreMenu(!doGoodMoreMenu)
    }
  }

  const setSearchclosedIcon = () => {
    LoginStore.query = ''
    setCloseIcon(false)
    setSearchIcon(false)
  }

  const handleCloseEvent = () => {
    setSubMenu(false)
    setEarnPointsShowArrowIcon('arrowDownBlueIcon')
    setDoGoodArrowIcon('arrowDownBlueIcon')
    setshowearnPointsArrowView('arrowNavDown')
    setshowDoGoodArrowView('arrowNavDown')
    setEarnPointsMoreMenu(false)
    setDoGoodMoreMenu(false)
    setSearchclosedIcon()
  }

  const handleDotNavigation = () => {
    setDotArrowIcon('arrowUpBlueIcon')
    setEarnPointsShowArrowIcon('arrowDownBlueIcon')
    setDoGoodArrowIcon('arrowDownBlueIcon')
    setSubMenu(true)
    setEarnPointsSubMenu(false)
    setDoGoodSubMenu(false)
  }
  const handleNavReset = () => {
    setDoGoodSubMenu(false)
    // setEarnPointsSubMenu(false)
    handleCloseEvent()
  }
  const handleResetNav = () => {
    setEarnPointsSubMenu(false)
    handleCloseEvent()
  }
  const subMenuList = (subNavigationCollection, className, currentPath, button) => (
    <ul
      data-testid='submenue-list'
      aria-hidden='true'
      className={twStyles.dropdownListSideNav}
      onClick={() => handleResetNav()}
    >

      {subNavigationCollection?.items?.map((navButton) => (
        <li className={twStyles.primaryNavList} key={`${navButton?.sys?.id}-li`}>
          <LinkButton
            handler={() => {
              if (navButton?.link.includes('#quiz')) {
                // router.push(navButton?.link)
                scrollToQuiz()
                // setEarnPointsSubMenu(false)
              }
            }}
            key={navButton?.sys?.id}
            className={`${className} ${getActiveStatus(
              currentPath,
              button?.link,
              button?.name,
            ) && 'deactive'}`}
            {...navButton}
            link={navButton?.link}
            behaviour={navButton?.behaviour}
            monthlyPromoRewardId={monthlyPromoRewardId}
            featuredSurvey={featuredSurvey}
            surveyContextualLoginText={surveyContextualLoginText}
            mosStartAndEndDate={mosStartAndEndDate}
          />
        </li>
      ))}
    </ul>
  )

  const handlingArrowNavigation = (button) => {
    if (button?.behaviour === 'Take_Survey') {
      let surveyID = ''
      const loadSurvey = async () => {
        surveyID = await getMasterSurveyID(
          featuredSurvey,
          monthlyPromoRewardId,
          mosStartAndEndDate,
        )
        feedbackSurvey(surveyID)
      }
      loadSurvey()
    }
    if (button.linkText === 'Coupons') triggerCouponsEvent()
    handleNavReset()
  }
  const arrowClass = (earnPointsMenu, doGoodMenu) => {
    if (earnPointsMenu || doGoodMenu) {
      return 'arrowDown w-auto p-04 ml-10 lg:ml-0 -left-10 z-1 relative'
    }

    return 'w-auto p-04 ml-10 lg:ml-0 arrowup -left-10 z-1 relative '
  }

  const getNavigationButtonArrowHandler = (buttonLinkText, earnpointsArrow, doGoodArrow) => {
    if (buttonLinkText === 'Earn Points') {
      return earnpointsArrow
    }
    if (buttonLinkText === 'Do Good') {
      return doGoodArrow
    }
    return ''
  }

  const getNavigationButton = (button, className, currentPath) => (
    <>
      <li
        className='list-none flex float-left nav-item lg:h-99 lg:py-30 relative bg-white'
        onMouseEnter={() => handleSubNavigation(button?.linkText)}
        onFocus={() => handleSubNavigation(button?.linkText)}
        aria-label='menu item'
      >
        <div className='flex flex-wrap items-center'>
          <LinkButton
            aria-label={button?.linkText}
            key={button?.sys && button?.sys.id}
            tabIndex={0}
            aria-expanded='false'
            className={`${className} ${getActiveStatus(
              currentPath,
              button?.link,
              button?.name,
            ) && 'active'}`}
            {...button}
            link={button?.link}
            handler={() => handlingArrowNavigation(button)}
            mosStartAndEndDate={mosStartAndEndDate}
          />
          {button?.linkText === 'Earn Points' && showEarnPointsSubMenu
            && subMenuList(earnPointsSubNavigationCollection, className, currentPath, button)}
          {button?.linkText === 'Do Good' && showDoGoodSubMenu
            && subMenuList(doGoodSubNavigationCollection, className, currentPath, button)}

          <span
            aria-hidden='true'
            className='arrowIconsHome cursor-pointer px-02 inline-flex items-center'
            onClick={() => handleSubNavigation(button?.linkText)}
          >
            <Icon
              className={arrowClass(showEarnPointsSubMenu, showDoGoodSubMenu)}
              name={
                getNavigationButtonArrowHandler(
                  button?.linkText,
                  showEarnPointsArrowIcon,
                  showDoGoodArrowIcon,
                )
              }
              onMouseEnter={() => handleSubNavigation(button?.linkText)}
            />
          </span>
        </div>

      </li>
    </>
  )

  const handleDigitalOffers = (button, className, currentPath) => {
    let digitalCouponsClass = ''
    if (
      button?.link === '/good-news'
      /* Commented this as rebates is shown as first on nav header */
      // || button?.link === '/rebates'
      || button?.link === '/brands'
      || button?.name === 'Impact'
      || button?.link === '/help'
      || button?.link === '/about'
      || button?.name === 'Beta Digital Offers'
    ) {
      digitalCouponsClass = `${className} ${getActiveStatus(currentPath, button?.link) ? 'active' : ''}`
    } else {
      digitalCouponsClass = `block xl:hidden pt-07 pb-07 font-normal 
                ${((button?.linkText === 'Earn Points' && earnPointsMoreMenu)
          || (button?.linkText === 'Do Good' && doGoodMoreMenu)) ? ' text-primary' : ''}`
    }
    if (button?.name === 'Beta Digital Offers') {
      digitalCouponsClass += twStyles.desktopView
    }
    if (button?.name === 'Beta Digital Offers Mobile Tablet') {
      digitalCouponsClass += twStyles.mobileView
    }
    return digitalCouponsClass
  }

  const getHamNavigationButton = (button, className, currentPath) => (
    <>
      <li className='flex w-200 flex-wrap earnPointsDropdown xl:bg-white'>
        <LinkButton
          key={button?.sys && button?.sys.id}
          role={role}
          className={handleDigitalOffers(button, className, currentPath)}
          {...button}
          handler={(e) => {
            if (digitalCouponsStore.linkMobileNumberToAccountPopup === COUPONS24
            || digitalCouponsStore.linkMobileNumberToAccountPopup
            === apiResponse.WALLET_NOT_FOUND) {
              digitalCouponsStore.linkMobileNumberToAccountPopup = ''
              handleCloseEvent()
            }
            if (e.code === 'Space' || e.code === 'NumpadEnter' || e.code === 'Enter') {
              router.push(button?.link)
            }
          }}
          mosStartAndEndDate={mosStartAndEndDate}
        />
        <div data-testid='ham-nav-div' aria-hidden='true' onClick={() => handleHambSubNavigation(button?.linkText)}>
          <Icon
            className={
              getNavigationButtonArrowHandler(
                button?.linkText,
                showearnPointsArrowView,
                showDoGoodArrowView,
              )
            }
            name={
              getNavigationButtonArrowHandler(
                button?.linkText,
                showEarnPointsArrowIcon,
                showDoGoodArrowIcon,
              )
            }
          />
        </div>
        {button?.linkText === 'Earn Points' && earnPointsMoreMenu
          && subMenuList(earnPointsSubNavigationCollection, className, currentPath, button)}
        {button?.linkText === 'Do Good' && doGoodMoreMenu
          && subMenuList(doGoodSubNavigationCollection, className, currentPath, button)}
      </li>
    </>
  )

  const shrinkHeader = () => {
    const navElem = document.getElementById('header-nav')
    const scanElem = document.getElementById('scan-receipt')
    if (window.scrollY > window.innerHeight / 25) {
      LoginStore.shrinkedHeader = true
      if (navElem && !navElem.classList?.contains('shrinked-nav')) navElem.className += ' shrinked-nav'
      if (scanElem && !scanElem.classList?.contains('shrinked-scan')) scanElem.className += ' shrinked-scan'
    } else {
      LoginStore.shrinkedHeader = false
      if (navElem && navElem.classList?.contains('shrinked-nav')) navElem.classList.remove('shrinked-nav')
      if (scanElem && scanElem.classList?.contains('shrinked-scan')) scanElem.classList.remove('shrinked-scan')
    }
  }

  const handleSearch = () => {
    if (
      LoginStore.query
      && LoginStore.query.trim() !== ''
      && LoginStore.query.trim() !== undefined
    ) {
      LoginStore.query = DOMPurify.sanitize(LoginStore.query)
      router.push(`/search?t=${LoginStore.query}`)
    }
  }
  const closeHandler = () => {
    LoginStore.query = ''
    setSearchBar(false)
    setSearchHovered(false)
  }

  const handleRewardModal = () => {
    LoginStore.showRewardMessage = false
  }

  const onKeyHandleDotNavigation = (event) => {
    if (event?.keyCode === 13) {
      handleDotNavigation()
    }
  }

  const onKeyDownHandleCloseEvent = (event) => {
    if (event?.keyCode === 13) {
      handleCloseEvent()
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', shrinkHeader)
    return () => window.removeEventListener('scroll', shrinkHeader)
  }, [])

  useEffect(() => {
    LoginStore.query = ''
    if (expandSearchBar) document.getElementById('focus').focus()
  }, [expandSearchBar])

  useEffect(() => {
    let allMessages
    let activeMessages
    if (storageAvailable('localStorage') && localStorage.getItem('loggedIn') === 'true' && LoginStore.rewardStatus && AimiaStore.readMessages) {
      const postPromotion = postPromotionMessages(
        AimiaStore.redeemRewards,
        monthlyPromoRewardId,
        featuredSurveyRewardType,
        messageCollection,
      )
      allMessages = postPromotion
        ? AimiaStore?.messages?.concat(postPromotion)
        : AimiaStore.messages
      activeMessages = readMessages(allMessages, AimiaStore.readMessages)
      const totalMessages = allMessages?.length || 0
      const unreadMessages = totalMessages - (activeMessages?.length || 0)
      AimiaStore.totalMessagesLength = totalMessages
      AimiaStore.unReadMessagesLength = unreadMessages
      sessionStorage.setItem(
        'unreadMessageCount',
        AimiaStore.unReadMessagesLength,
      )
      AimiaStore.messagesLoading = sessionStorage.getItem('unreadMessageCount')
    }
  }, [AimiaStore.readMessages])
  return (
    <>
      {LoginStore.showRewardMessage && (
        <ModalV2
          variant={variants?.paragraphModal?.headerReward}
          closeHandler={() => handleRewardModal()}
          okHandlerRedirectUrl={staticRoutes && staticRoutes.myMessages}
        />
      )}
      <div className='w-full shadow-headernav bg-white xl:relative xl:z-1'>
        <div>
          <div className={twStyles.headerMainWrapper}>
            <nav
              id='header-nav'
              aria-label='menu bar'
              className={twStyles.headerWrapper}
            >
              <div className=' container flex flex-wrap justify-between items-center mx-auto lg:relative'>
                <div className={twStyles.hamburgerButton}>
                  <button
                    type='button'
                    value='hamburger'
                    aria-label='menu button'
                    onClick={() => {
                      setSubMenu(true)
                      handleHambSubNavigation('Earn Points')
                    }}
                  >
                    <Icon name='hamburger' />
                  </button>
                </div>
                <div className='xl:w-10/12 desktopWrapper86 bg-white -top-3 xl:top-0 relative'>
                  <div className='flex-1 flex items-center justify-center xl:justify-start'>
                    <Button
                      to='/'
                      asPath='/'
                      aria-label='pggoodeveryday logo'
                      onClick={triggerLogoClickEvent}
                    >
                      <div className={twStyles.logoWrapper}>
                        <div className={twStyles.headerLogo}>
                          <span id='Header-PGGELogo'><Icon name='PGGELogo' /></span>
                        </div>
                      </div>
                    </Button>
                    <div className={twStyles.primaryNav}>
                      <div className={twStyles.navMenu}>
                        <ul aria-label='menu item list' className='navigation-item-group-new'>
                          {primaryNavCollection
                            && primaryNavCollection.items
                            && primaryNavCollection.items.map((primaryLink) => getNavigationButton(
                              primaryLink,
                              twStyles.primaryNavItem,
                              router && router.asPath,
                            ))}
                        </ul>
                      </div>
                      <div
                        className={twStyles.threeDots}
                        onMouseEnter={() => {
                          setDoGoodSubMenu(false)
                          setSubMenu(true)
                          setEarnPointsShowArrowIcon('arrowDownBlueIcon')
                          setDoGoodArrowIcon('arrowDownBlueIcon')
                        }}
                        onFocus={() => {
                          setSubMenu(true)
                        }}
                      >
                        {!expandSearchBar && (
                          <div
                            className='flex flex-wrap threeDots md:pt-04 xxl:pt-06'
                          >
                            <button
                              type='button'
                              aria-labelledby='Hamburger-Menu'
                              aria-expanded='false'
                              value='button'
                              tabIndex={0}
                              onMouseEnter={() => handleDotNavigation()}
                              onFocus={() => handleDotNavigation()}
                              onClick={() => handleDotNavigation()}
                              onKeyDown={(event) => onKeyHandleDotNavigation(event)}
                              className={twStyles.threeDotsDropdown}
                            >
                              <span id='Hamburger-Menu' className='text-16 sr-only leading-leading19 font-normal relative -top-3'>More </span>
                            </button>
                            <div className='flex-wrap items-center hidden xl:flex'>
                              <Icon
                                className={twStyles.arrowIconThreeDots}
                                name={showDotArrowIcon}
                              />
                            </div>
                          </div>
                        )}
                        {showSubMenu && (
                        <div
                          className={twStyles.hamburgerMenu.nav.mainWrapper}
                        >
                          <div
                            className={twStyles.hamburgerMenu.nav.mainWrapperClose}
                            data-testid='submenu-close-btn-out'
                            role='button'
                            tabIndex={0}
                            onClick={() => { handleCloseEvent() }}
                            onKeyDown={(event) => onKeyDownHandleCloseEvent(event)}
                          />
                          <div
                            data-testid='submenu-out-btn'
                            className={twStyles.hamburgerMenu.nav.wrapper}
                            role='button'
                            tabIndex={-1}
                            onClick={() => { if (isDesktop) handleCloseEvent() }}
                            onKeyDown={
                              (event) => { if (isDesktop) onKeyDownHandleCloseEvent(event) }
                            }
                          >
                            <div
                              role='button'
                              data-testid='submenu-close-btn'
                              tabIndex={0}
                              className={twStyles.hamburgerMenu.nav.closeIcon}
                              onClick={() => { handleCloseEvent() }}
                              onKeyDown={(event) => onKeyDownHandleCloseEvent(event)}
                            >
                              <Icon name='grayClose' className='w-16 xl:hidden' />
                            </div>
                            <div className='block xl:hidden relative py-30  pr-15'>
                              <div className='flex border-b-half border-sign-head'>
                                {!searchIcon ? (
                                  <Icon name='graySearch' className='w-20 pb-10' />
                                ) : (
                                  <Button handler={handleSearch}>
                                    <Icon name='blueSearch' className='w-20 pb-10' />
                                  </Button>

                                )}
                                <ModernWebSearch config={algoliaSearchConfig}>
                                  <AutoComplete handleSearchIcon={() => {
                                    setSearchIcon(true)
                                    setCloseIcon(true)
                                  }}
                                  />
                                </ModernWebSearch>
                                {closeIcon ? (
                                  <div
                                    data-testid='close-btn-div'
                                    onMouseEnter={() => setCloseHovered(true)}
                                    onMouseLeave={() => setCloseHovered(false)}
                                  >
                                    <button
                                      type='button'
                                      aria-label='Search Close'
                                      onClick={setSearchclosedIcon}
                                    >
                                      {closeHovered ? (
                                        <Icon name='closeBlue' className='w-16' />
                                      ) : (
                                        <Icon name='grayClose' className='w-16' />
                                      )}
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <ul className='pt-0 bg-white lg:pt-0'>
                              {sideNavCollection?.items.map(
                                (navItem) => getHamNavigationButton({
                                  ...navItem,
                                }, twStyles.hamburgerMenu.nav.item, router.asPath),
                              )}
                            </ul>
                          </div>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='loginsearch bg-white flex justify-end items-center xl:static xl:-left-0 xl:left-0 xl:h-99 min-w-45'>
                  <div className={twStyles.searchWrapper}>
                    {!expandSearchBar && router && router.asPath
                      && !router.asPath.includes('search') && (
                        <div
                          data-testid='expand-search-btn'
                          className='relative top-5 right-0'
                          onMouseEnter={() => setSearchHovered(true)}
                          onMouseLeave={() => setSearchHovered(false)}
                        >
                          <Button
                            handler={() => setSearchBar(true)}
                            aria-label='search'
                            aria-expanded='false'
                          >
                            {searchHovered ? (
                              <Icon name='blueSearch' className='w-25 h-25' />
                            ) : (
                              <Icon name='graySearch' className='w-25 h-25 p-01' />
                            )}
                          </Button>
                        </div>
                    )}
                    {expandSearchBar && (
                      <div className='absolute w-full right-0 top-0 pb-01'>
                        <div className='search-animation'>
                          <div
                            className='right-0 top-0 pb-01 w-240 xxl:w-330 flex absolute z-1 md:border-b-half bg-white pl-04 border-sign-head'
                          >
                            <Button
                              handler={handleSearch}
                              className={twStyles.searchFieldWrapper}
                              aria-label='Search'
                              aria-expanded='true'
                            >
                              <Icon
                                name='blueSearch'
                                className='w-25 h-25 pl-01 bg-white absolute top-0 left-0'
                              />
                            </Button>
                            <div className='instant-search w-full'>
                              <ModernWebSearch config={algoliaSearchConfig}>
                                <AutoComplete />
                              </ModernWebSearch>
                            </div>
                            <div
                              data-testid='change-close-btn-color'
                              onMouseEnter={() => setCloseHovered(true)}
                              onMouseLeave={() => setCloseHovered(false)}
                            >
                              <Button
                                handler={closeHandler}
                                className='focus:outlinenone'
                              >
                                {closeHovered ? (
                                  <Icon
                                    name='closeBlue'
                                    className='w-16 h-17 relative right-0 top-0'
                                  />
                                ) : (
                                  <Icon
                                    name='grayClose'
                                    className='w-16 relative right-0 top-0'
                                  />
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {router && !router.asPath.includes('/login/')
                    && !router.asPath.includes('/email-verification/') ? (
                      <div className={twStyles.userManagement}>
                        <SignIn
                          oktaLabels={oktaLabels}
                          pathName={router && router.asPath}
                          selectCause={selectCause}
                          confirmationModal={confirmationModal}
                          userRightMenuCollection={userRightMenuCollection}
                          emailVerificationModalContent={emailVerificationModalContent}
                          cascadeSampleIdAddToBasketForStage={cascadeSampleIdAddToBasketForStage}
                          cascadeSampleIdAddToBasketForProd={cascadeSampleIdAddToBasketForProd}
                          campaignSamplesIdCollection={campaignSamplesIdCollection}
                          inActiveCampaigns={inActiveCampaigns}
                          privacyText={privacyText}
                        />
                      </div>
                    ) : null}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* <div className='container mx-auto'>
        <div
          id='scan-receipt'
          className={
            router.asPath === '/'
              ? `${twStyles.aboutHomeHeaderScan}${LoginStore.aboutExpanded && ' AboutHomeExpand'}`
              : twStyles.headerscan
          }
        >
          {showScanReceiptButton && addReceiptButton && (
            <IconButton
              handler={() => {
                ReceiptScanStore.setWidgetVisibility(true)
              }}
              {...addReceiptButton}
            />
          )}
        </div>
      </div> */}
      <style jsx>{styles}</style>
    </>
  )
}

Header.propTypes = {
  primaryNavCollection: PropTypes.instanceOf(Object).isRequired,
  sideNavCollection: PropTypes.instanceOf(Object).isRequired,
  oktaLabels: PropTypes.oneOfType([PropTypes.object]).isRequired,
  router: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selectCause: PropTypes.oneOfType([PropTypes.object]).isRequired,
  confirmationModal: PropTypes.instanceOf(Object).isRequired,
  userRightMenuCollection: PropTypes.instanceOf(Object).isRequired,
  monthlyPromoRewardId: PropTypes.string,
  featuredSurveyRewardType: PropTypes.string,
  emailVerificationModalContent: PropTypes.oneOfType([PropTypes.object]),
  cascadeSampleIdAddToBasketForStage: PropTypes.string.isRequired,
  cascadeSampleIdAddToBasketForProd: PropTypes.string.isRequired,
  campaignSamplesIdCollection: PropTypes.oneOfType([PropTypes.object]),
  inActiveCampaigns: PropTypes.string.isRequired,
  privacyText: PropTypes.string.isRequired,
  earnPointsSubNavigationCollection: PropTypes.instanceOf(Object).isRequired,
  doGoodSubNavigationCollection: PropTypes.instanceOf(Object).isRequired,
  featuredSurvey: PropTypes.bool,
  surveyContextualLoginText: PropTypes.string,
  messageCollection: PropTypes.instanceOf(Object),
  mosStartAndEndDate: PropTypes.instanceOf(Object),
  role: PropTypes.string,
}

Header.defaultProps = {
  monthlyPromoRewardId: '',
  featuredSurveyRewardType: '',
  emailVerificationModalContent: null,
  campaignSamplesIdCollection: null,
  featuredSurvey: false,
  surveyContextualLoginText: '',
  messageCollection: null,
  mosStartAndEndDate: null,
  role: null,
}

export default withRouter(observer(Header))
