import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import { observer } from 'mobx-react'
import { withRouter } from 'next/router'
import {
  oktaSignup,
  oktaLogin,
} from '../../../constants/texts'
import ModalV2 from '../../../components/Modal/ModalV2'
import {
  triggerProfileLogInEvent,
} from '../../../helpers/gaEvent.helper'
import LoginStyles from './Login.styles'
import { sessionClearing } from '../okta.helpers'
import LoginStore from '../../../components/UserManagement/Login-store'
import {
  hanldeOutOfStockRedirect,
  oktaAuthentication,
} from '../../../helpers/login.helper'
import staticRoutes from '../../../../config/router.config'
import redirectTo, {
  getParameterByName,
} from '../../../helpers/redirect.helpers'
import { variants } from '../../../constants/variants'
import { storageAvailable } from '../../../adapters/serverside.adapters'

const ConfirmationPopup = dynamic(
  () => import('../SignUpForm/ConfirmationPopup'),
  { ssr: false },
)

const LoginForm = ({
  handleMenu,
  oktaLabels,
  confirmationModal,
  pageSource,
  campaignSamplesIdCollection,
  router,
}) => {
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(true)
  const [showIneligiblePromoContent, setShowIneligiblePromoContent] = useState(
    false,
  )
  const [showIneligiblePromoContentCMF, setShowIneligiblePromoContentCMF] = useState(
    false,
  )
  useEffect(() => {
    switch (LoginStore.loginFlowPopup) {
      case 'ErrorModal':
        setShowErrorModal(true)
        break
      case 'IneligiblePromoContent':
        setShowIneligiblePromoContent(true)
        break
      case 'IneligiblePromoContentCMF':
        setShowIneligiblePromoContentCMF(true)
        break
      default:
        break
    }
    LoginStore.loginFlowPopup = null
  }, [LoginStore.loginFlowPopup])
  const handleCheckSpecialIncentive = (sampleName) => {
    let isSpecialIncentive
    campaignSamplesIdCollection?.items?.map((item) => {
      if (item?.name === sampleName) {
        isSpecialIncentive = item?.isSpecialIncentive
      }
      return isSpecialIncentive
    })
    return isSpecialIncentive
  }

  useEffect(() => {
    triggerProfileLogInEvent()
  }, [])

  const handleLogout = () => {
    sessionClearing()
  }

  useEffect(() => {
    const slug = getParameterByName('utm_campaign')
    const siteSettingsData = { campaignSamplesIdCollection }
    const isSpecialSample = handleCheckSpecialIncentive(
      getParameterByName('utm_campaign'),
    )
    if (isSpecialSample) {
      hanldeOutOfStockRedirect(
        siteSettingsData,
        slug,
        staticRoutes.loginOutOfStock,
      )
    }

    if (pageSource === 'LoginPage') LoginStore.showAlertBar = false
    if (
      storageAvailable('localStorage')
      && localStorage.getItem('janrainCaptureToken')
      && localStorage.getItem('janrainCaptureToken').length > 4
    ) {
      LoginStore.showSignInPopup = false
      LoginStore.simplifiedSignupFlow = false
      LoginStore.simplifiedSignupFlowWithCompleteProfileSurvey = false
    }
  }, [])

  return (
    <>
      {showIneligiblePromoContent && (
        <ModalV2
          variant={variants?.paragraphModal?.accountSetupIneligiblePromoContent}
          closeHandler={() => redirectTo(true, staticRoutes.homepage)}
        />
      )}
      {showIneligiblePromoContentCMF && (
        <ModalV2
          variant={variants?.paragraphModal?.accountSetupIneligiblePromoContent}
          closeHandler={() => redirectTo(true, staticRoutes.earnPoints)}
        />
      )}

      {LoginStore.showThankYouPopup && (
        <ConfirmationPopup
          confirmationModal={confirmationModal}
          oktaLabels={oktaLabels}
          pageSource='login'
          charityLabel={
            confirmationModal?.charitiesCollection?.items?.filter(
              (charity) => charity?.charityId === LoginStore.charityID,
            )[0].registrationSuccessfulMessage
          }
        />
      )}
      {showErrorModal && (
        <ModalV2
          variant={variants?.paragraphModal?.layoutError}
          closeHandler={() => handleLogout()}
        />
      )}
      {showLoginForm && (
        <ModalV2
          variant={variants?.loginForm}
          pageSource={pageSource}
          closeHandler={() => {
            LoginStore.showSignInPopup = false
            setShowLoginForm(false)
            handleMenu(false)
          }}
          onLoginCLick={() => {
            if (router.pathname === '/good-news/[slug]') {
              localStorage.setItem('articlePendingUrl', LoginStore.articlePendingUrl)
            }
            setShowLoginForm(false)
            const exactPath = window?.location?.href
            oktaAuthentication(oktaLogin, exactPath)
          }}
          onSignupClick={() => {
            const exactPath = window?.location?.href
            oktaAuthentication(oktaSignup, exactPath)
          }}
          styles='loginPopup'
        />
      )}
      <style jsx global>
        {LoginStyles}
      </style>
    </>
  )
}

LoginForm.propTypes = {
  oktaLabels: PropTypes.shape({
    loginEmailFieldLabel: PropTypes.string,
    loginPasswordFieldLabel: PropTypes.string,
    loginPasswordForgotPasswordQuestion: PropTypes.string,
    loginPasswordForgotPasswordText: PropTypes.string,
    loginButtonText: PropTypes.string,
    loginPasswordAccountAvailabilityQuestion: PropTypes.string,
    loginButtonSignUpText: PropTypes.string,
    loginButtonSignInText: PropTypes.string,
  }),
  confirmationModal: PropTypes.instanceOf(Object).isRequired,
  pageSource: PropTypes.string,
  router: PropTypes.instanceOf(Object).isRequired,
  campaignSamplesIdCollection: PropTypes.oneOfType([PropTypes.object])
    .isRequired,
}

LoginForm.defaultProps = {
  pageSource: '',
  oktaLabels: {
    loginEmailFieldLabel: null,
    loginPasswordFieldLabel: null,
    loginPasswordForgotPasswordQuestion: null,
    loginPasswordForgotPasswordText: null,
    loginButtonText: null,
    loginPasswordAccountAvailabilityQuestion: null,
    loginButtonSignUpText: null,
    loginButtonSignInText: null,
  },
}

export default withRouter(observer(LoginForm))
