import { useState, useEffect, useRef } from 'react'
import { deviceWidth } from '../../constants/configuration'

function useScreenSize() {
  const [state, _setState] = useState({
    isMobile: true,
    isTablet: false,
    isDesktop: false,
  })
  const screenStateRef = useRef(state)
  const setScreenState = (data) => {
    screenStateRef.current = data
    _setState(data)
  }

  useEffect(() => {
    function checkScreenSize() {
      const currentState = screenStateRef.current
      if (window.innerWidth <= deviceWidth.smallScreenEnd) {
        const setMobile = !currentState.isMobile
          && setScreenState({
            isMobile: true,
            isTablet: false,
            isDesktop: false,
          })
        return setMobile
      }
      if (window.innerWidth <= deviceWidth.tabWidthEnd) {
        const setTablet = !currentState.isTablet
          && setScreenState({
            isMobile: true,
            isTablet: true,
            isDesktop: false,
          })
        return setTablet
      }
      const setDesktop = !currentState.isDesktop
        && setScreenState({
          isMobile: false,
          isTablet: false,
          isDesktop: true,
        })
      return setDesktop
    }
    checkScreenSize()
    window.addEventListener('resize', checkScreenSize)
    return () => window.removeEventListener('resize', checkScreenSize)
  }, [])

  return state
}

export default useScreenSize
