module.exports = {
  smartPhoneWidth: 767,
  tabletWidth: 1023,
  tabletProWidth: 1279,
  desktopProWidth: 1366,
  homeSamallCarousal: {
    deskW: 150,
    deskH: 65,
    mobW: 150,
    mobH: 65,
    tabW: 150,
    tabH: 65,
  },
}
