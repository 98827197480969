const getQueryParams = (params, url) => {
  const href = url
  const reg = new RegExp(`[?&]${params}=([^&#]*)`, 'i')
  const queryString = reg.exec(href)
  return queryString ? queryString[1] : null
}

export default getQueryParams

export const scrollToQuiz = (diff = 0) => {
  const getOffsetTop = (elem) => {
    let distance = 0
    if (elem.offsetParent) {
      do {
        distance += elem.offsetTop
        elem = elem.offsetParent
      } while (elem)
    }
    return distance < 0 ? 0 : distance
  }
  const elem = document.querySelector('#quizSection')
  if (elem) {
    const offset = getOffsetTop(elem)
    window.scrollTo({ left: 0, top: offset - diff, behavior: 'smooth' })
  }
}
