import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import LoginForm from '../../okta/forms/LoginForm/LoginForm'
import redirectTo from '../../helpers/redirect.helpers'
import oktaFormErrors from '../../okta/forms/oktaFormErrors'
import { handleClick } from '../../helpers/okta.helper'

const handleLoginSuccess = () => {
  const searchParamsObject = new URLSearchParams(window.location.search)
  if (searchParamsObject.has('callback')) {
    redirectTo(searchParamsObject.get('callback'))
  } else {
    redirectTo('/')
  }
}

const LoginPage = observer(({
  oktaLabels,
  selectCause,
  confirmationModal,
  emailVerificationModalContent,
  cascadeSampleIdAddToBasketForStage,
  cascadeSampleIdAddToBasketForProd,
  campaignSamplesIdCollection,
  inActiveCampaigns,
  privacyText,
}) => (
  <LoginForm
    oktaFormErrors={oktaFormErrors}
    handleLoginSuccess={handleLoginSuccess}
    handleMenu={() => handleClick(false)}
    oktaLabels={oktaLabels}
    selectCause={selectCause}
    confirmationModal={confirmationModal}
    emailVerificationModalContent={emailVerificationModalContent}
    cascadeSampleIdAddToBasketForStage={cascadeSampleIdAddToBasketForStage}
    cascadeSampleIdAddToBasketForProd={cascadeSampleIdAddToBasketForProd}
    campaignSamplesIdCollection={campaignSamplesIdCollection}
    inActiveCampaigns={inActiveCampaigns}
    privacyText={privacyText}
    pageSource='LoginPopUp'
  />
))

LoginPage.propTypes = {
  oktaLabels: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selectCause: PropTypes.oneOfType([PropTypes.object]).isRequired,
  confirmationModal: PropTypes.instanceOf(Object).isRequired,
  emailVerificationModalContent: PropTypes.oneOfType([PropTypes.object]),
  cascadeSampleIdAddToBasketForStage: PropTypes.string.isRequired,
  cascadeSampleIdAddToBasketForProd: PropTypes.string.isRequired,
  campaignSamplesIdCollection: PropTypes.oneOfType([PropTypes.object]),
  privacyText: PropTypes.string.isRequired,
}

export default LoginPage
