import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import { observer } from 'mobx-react'
import dynamic from 'next/dynamic'
import { variants } from '../../constants/variants'
import LoginPage from '../../containers/Login/Login'
import Header from '../Header/Header'
import styles from './layout.styles'
import twStyles from './Layout.tw-styles'
import SellerStore from '../SellerLising/Seller.store'
import AlertBox from '../Header/AlertBox'
import LoginStore from '../UserManagement/Login-store'
import {
  skipToContent,
  receiptScanDetails,
  completeProfileEndCard,
} from '../../constants/texts'
import staticRoutes from '../../../config/router.config'
import {
  getPageVisitCount,
  setSiteVisitCount,
  getSiteVisitCount,
  setPageVisitCount,
} from '../../okta/forms/LoginForm/Login.helper'
import { sessionClearing, oktaLogout } from '../../okta/forms/okta.helpers'
import AimiaStore from '../../containers/AIMIA/aimia.store'
import ReceiptScanStore from '../../containers/ReceiptScan/ReceiptScan.store'
import {
  triggerLoginEvent,
  triggerRegisiterUnSuccessfulEvent,
  triggerRegisiterEvent,
  triggerRegistrationSuccessEvent,
  triggerUnsuccessfulLoginEvent,
} from '../../helpers/gaEvent.helper'
import telemetry from '../../adapters/telemetry.adapter'
import { storageAvailable } from '../../adapters/serverside.adapters'
import LayoutStore from './Layout.store'
import standardEvent, { standardEventForInvalidProgramId, standardEventForOkta } from '../../containers/AuthContainer/standardEventErrorLog'
import {
  STATUS_1010, STATUS_1011, STATUS_1012, STATUS_1071,
} from '../../constants/status-code'

// Dynamic Components
const ModalV2 = dynamic(() => import('../Modal/ModalV2'), {
  ssr: false,
})

const SessionExpiry = dynamic(() => import('../SessionExpiry/SessionExpiry'), {
  ssr: false,
})

const Footer = dynamic(() => import('../Footer/Footer'), {
  ssr: false,
})

// Dynamically import helper functions
let feedbackSurvey
let getMasterSurveyID
let handleLoadCompleteProfileSurvey
let isSegmantaLoaded
let isSurveyCompleted
let fetchPointsBalanceApi
let restrictedPagesForCompleteProfileOverlay
let handleCountryRedirection

const loadHelpers = async () => {
  const segmantaHelpers = await import('../../helpers/segmanta.helper')
  feedbackSurvey = segmantaHelpers.feedbackSurvey
  getMasterSurveyID = segmantaHelpers.getMasterSurveyID
  handleLoadCompleteProfileSurvey = segmantaHelpers.handleLoadCompleteProfileSurvey
  isSegmantaLoaded = segmantaHelpers.isSegmantaLoaded
  isSurveyCompleted = segmantaHelpers.isSurveyCompleted

  const apiHelpers = await import('../../helpers/api.helper')
  fetchPointsBalanceApi = apiHelpers.fetchPointsBalanceApi

  const layoutHelpers = await import('../../helpers/layout.helper')
  restrictedPagesForCompleteProfileOverlay = layoutHelpers.restrictedPagesForCompleteProfileOverlay
  handleCountryRedirection = layoutHelpers.handleCountryRedirection
}

const Layout = (props) => {
  const {
    children,
    header,
    footer,
    modal,
    brandsDoingGood,
    className,
    router,
    oktaLabels,
    selectCause,
    confirmationModalLabels,
    siteSettings,
    emailVerificationModalContent,
    messageCollection,
    alertBarCollections,
    errorPage,
  } = props
  let exactPath = router.asPath
  const {
    myMessages, quiz,
  } = staticRoutes
  const {
    headerAlertBoxRightSideContents,
    campaignSamplesIdCollection,
  } = siteSettings

  useEffect(() => {
    LoginStore.errorPage = errorPage
    LayoutStore.ModalContent = modal

    // Load helper functions dynamically
    loadHelpers()
  }, [])

  const handleLogout = () => {
    if (localStorage.getItem('JWTtoken')) {
      sessionClearing()
    } else oktaLogout()
    LoginStore.showErrorModal = false
  }

  const fetchMemberHistoryAPICall = async () => {
    if (
      LoginStore.rewardStatus
      && !AimiaStore.messageFetched
    ) {
      AimiaStore.messageFetched = true
      await AimiaStore.fetchMessagesList()
      await AimiaStore.fetchMemberHistoryAction()
      if (AimiaStore.MemberTransactionsArray && AimiaStore.MemberTransactionsArray.length >= 0) {
        AimiaStore.MemberTransactionsArray.map((item) => {
          if (['Survey_TellMe'].includes(item?.transactionTypeExternalReference)) {
            LoginStore.completeProfile20SurveyTaken = true
          }
          return null
        })
      }
      if (AimiaStore.issuedRewards && AimiaStore.issuedRewards.length >= 0) {
        AimiaStore.issuedRewards.map((reward) => {
          if (reward.rewardTypeExternalReference === 'Sweep_YO_PG') {
            LoginStore.buildYourProfileSurveyTaken = true
          }
          if (reward.rewardTypeExternalReference === 'Sweep_YO_PG_Complete') {
            LoginStore.completeProfile20SurveyTaken = true
          }
          return null
        })
      }
    }
  }

  const handleNetworkErrorPopup = () => {
    LoginStore.showNetworkErrorModal = false
    if (LoginStore.networkErrorFunc) {
      LoginStore.networkErrorFunc()
      return
    }
    router.reload()
  }
  const loadSurvey = async () => {
    let surveyID = ''
    surveyID = await getMasterSurveyID(
      siteSettings.featuredSurvey,
      siteSettings.monthlyPromoRewardId,
      siteSettings.mosStartAndEndDate,
    )
    feedbackSurvey(surveyID)
  }

  useEffect(() => {
    if (!LoginStore.userLoggedIn) {
      return () => {}
    }

    const receiptPattern = new RegExp(receiptScanDetails.receiptPatternRegex)
    const receiptScanPopup = receiptPattern.test(window?.location?.search)
    if (receiptScanPopup && siteSettings?.micsServiceNotAvailable) {
      ReceiptScanStore.showWidget = false
      router.push(staticRoutes.maintenance)
    }
    return () => {}
  }, [exactPath, LoginStore.userLoggedIn])

  useEffect(() => {
    LoginStore.isLayoutAvailed = true
    SellerStore.init(brandsDoingGood)
    if (exactPath !== '/' && exactPath.slice(-1) === '/') {
      exactPath = exactPath.slice(0, exactPath.length - 1)
    }
    if (LoginStore.rewardStatus) {
      fetchMemberHistoryAPICall()
        .then(() => {
          if (typeof window !== 'undefined') {
            if (LoginStore.openSurvey && !LoginStore.openTellMeAboutSurvey) {
              loadSurvey()
              LoginStore.openSurvey = false
            } else if (LoginStore.openFeedbackSurvey) {
              LoginStore.openFeedbackSurvey = false
              isSegmantaLoaded(async () => {
                const status = await isSurveyCompleted(process.env.DEQ_FEEDBACK_DEC_THINK_TANK)
                if (!status) {
                  feedbackSurvey(process.env.DEQ_FEEDBACK_DEC_THINK_TANK)
                }
              })
            }
            if (!LoginStore.completeProfile20SurveyTaken) {
              // check if user completed the profile
              isSegmantaLoaded(async () => {
                const completeProfileStatus = await isSurveyCompleted(
                  process.env.DEQ_COMPLETE_PROFILE_2_0,
                )
                if (completeProfileStatus) {
                  LoginStore.completeProfile20SurveyTaken = true
                }
              })
            }
          }
        })
        .catch((err) => console.info(err))
    }
  }, [LoginStore.rewardStatus, AimiaStore.totalPointsAvailable])

  useEffect(() => {
    if (LoginStore.rewardStatus
      && restrictedPagesForCompleteProfileOverlay().indexOf(exactPath) < 0
      && exactPath && exactPath.includes(myMessages) !== true
      && exactPath && exactPath.includes(quiz) !== true
      && exactPath && exactPath.includes('video-gating-survey') !== true
      && exactPath && exactPath.includes('simplified-signup') !== true
      && exactPath && exactPath.includes('digital-coupons') !== true) {
      setPageVisitCount()
    }
    /* Earnpoints endcard CTA code */
    if (LoginStore.userLoggedIn === true) {
      isSegmantaLoaded(async () => {
        window.SEG_EMBED_API.on('SURVEY_GOAL_REACHED', async (scenarioid) => {
          if (scenarioid) {
            if (scenarioid.toString() === process.env.DEQ_COMPLETE_PROFILE_2_0) {
              const segmentaEmbed = document.getElementsByClassName('segmanta-embed')[0]
              if (segmentaEmbed) segmentaEmbed.style.display = 'none'
              document.body.className = document.body.className.replace('segmanta-in-fullscreen', '')
              LoginStore.showCompleteProfileEndCard = true
            }
          }
        })
      })
    }
  }, [LoginStore.rewardStatus])

  useEffect(() => {
    if (
      storageAvailable('sessionStorage')
      && sessionStorage.getItem('completeProfileOverlay') === 'false'
      && typeof sessionStorage !== 'undefined'
      && LoginStore.showCompleteProfileOverlay === false
    ) {
      LoginStore.showCompleteProfileOverlay = false
    } else if (
      LoginStore.rewardStatus
      && getPageVisitCount() === 2
      && getSiteVisitCount() < 3
      && restrictedPagesForCompleteProfileOverlay().indexOf(exactPath) < 0
      && exactPath.includes(myMessages) !== true
      && exactPath.includes(quiz) !== true
      && LoginStore.completeProfileCTALaunched !== true
      && typeof localStorage !== 'undefined'
  && !localStorage.getItem('disableCompleteProfileOverlay')
      && LoginStore.completeProfile20SurveyTaken === false
      && typeof sessionStorage !== 'undefined' && !sessionStorage.getItem('PromotionalSurvey')
      && LoginStore.videoGating === false
      && LoginStore.iohVideoGating === false
    ) {
      if (!LoginStore.completeProfileCTALaunched) setSiteVisitCount()
      LoginStore.showCompleteProfileOverlay = false
    } else if (
      LoginStore.rewardStatus
      && LoginStore.showCompleteProfileOverlay === true
      && LoginStore.completeProfile20SurveyTaken === false
    ) {
      if (!LoginStore.completeProfileCTALaunched) setSiteVisitCount()
      handleLoadCompleteProfileSurvey()
      LoginStore.showCompleteProfileOverlay = false
    }
  }, [LoginStore.showCompleteProfileOverlay,
    LoginStore.completeProfile20SurveyTaken])

  useEffect(() => {
    if (LoginStore.triggerTrakers) {
      const data = LoginStore.profileData
      const campaignName = LoginStore.traitCampaignName === 'account-setup'
        ? ''
        : LoginStore.traitCampaignName

      const page = `/signup/${campaignName}`
      switch (LoginStore.triggerTrakers) {
        case 'login-success':
          telemetry.trackEvent('Login Button Success', {
            page: 'login',
            consumerId: data?.consumerId,
            statusCode: LoginStore.loginStatusCode,
          })
          triggerLoginEvent()
          break
        case 'login-failed':
          triggerUnsuccessfulLoginEvent()
          telemetry.trackEvent(
            'Login Button Failure',
            {
              page: 'login',
              msg: data,
              statusCode: LoginStore.loginStatusCode,
            },
            true,
          )
          break
        case 'registration-failed':
          telemetry.trackEvent(
            'Registration Button Failure',
            {
              page,
              // email: profileData.profile.emailAddress,
              msg: data,
              statusCode: LoginStore.loginStatusCode,
            },
            true,
          )
          triggerUnsuccessfulLoginEvent()
          triggerRegisiterUnSuccessfulEvent()
          break
        case 'campaign':
          triggerRegistrationSuccessEvent()
          telemetry.trackEvent('Registration Button Success', {
            page,
            consumerId: data?.consumerId,
            email: data?.emailAddress,
            statusCode: LoginStore.loginStatusCode,
          })
          triggerRegisiterEvent()
          break
        case 'sample':
          telemetry.trackEvent('Sampling Registration Button Success', {
            page,
            consumerId: data?.consumerId,
            email: data?.emailAddress,
            statusCode: LoginStore.loginStatusCode,
          })
          triggerRegisiterEvent()
          break
        case 'rate-limit':
          telemetry.trackEvent(
            'Registration Button Failure due to rate limit',
            {
              page: 'login',
              msg: LoginStore.profileData,
              statusCode: LoginStore.loginStatusCode,
            },
            true,
          )
          triggerUnsuccessfulLoginEvent()
          break
        case 'profile-missing':
          telemetry.trackEvent(
            'Login Failure due to Profile data missing',
            {
              page: 'login',
              // email: profileData.profile.emailAddress,
              msg: LoginStore.profileData,
              statusCode: LoginStore.loginStatusCode,
            },
            true,
          )
          triggerUnsuccessfulLoginEvent()
          break
        default:
          break
      }
      LoginStore.triggerTrakers = null
    }
  }, [LoginStore.triggerTrakers])

  useEffect(() => {
    if (LoginStore.oktaErrorEvent) {
      switch (LoginStore.oktaErrorEvent) {
        case STATUS_1010:
        case STATUS_1071:
        case STATUS_1011:
          standardEventForOkta(LoginStore.oktaErrorEvent)
          break
        case STATUS_1012:
          standardEventForInvalidProgramId(LoginStore.oktaErrorEvent)
          break
        default:
          break
      }
      LoginStore.oktaErrorEvent = null
    }
  }, [LoginStore.oktaErrorEvent])

  useEffect(() => {
    if (LoginStore.oktaLoginErrorEvent.code) {
      standardEvent(
        LoginStore.oktaLoginErrorEvent.code,
        null,
        null,
        LoginStore.oktaLoginErrorEvent.response,
        LoginStore.profileData,
      )
    }
    LoginStore.oktaLoginErrorEvent.code = null
  }, [LoginStore.oktaLoginErrorEvent.code])

  return (
    <div className={twStyles.layoutOuterWrapper} data-nosnippet>
      <a
        href='#content'
        className='accessibility-hidden font-semibold container mx-auto mt-05'
      >
        {skipToContent}
      </a>
      {LoginStore.showAlertBar
        && router.asPath !== '/digital-coupons/' && (
          <div
            role='navigation'
            aria-label='secondary navigation'
            className={LoginStore.shrinkedHeader ? 'alertShrink' : ''}
          >
            <div className={twStyles.layoutAlerExternaltWrapper}>
              <AlertBox
                showAlertBarRightSideContents={headerAlertBoxRightSideContents}
                alertBar={alertBarCollections
                  && alertBarCollections?.alertBarButtonsCollection?.items}
                siteSettings={siteSettings}
                tuayCompletedBodyText={alertBarCollections?.tuayCompletedBodyText}
                tuayPopupCtaText={alertBarCollections?.tuayPopupCtaText}
                tuayCompletedHeaderText={alertBarCollections?.tuayCompletedHeaderText}
              />
            </div>
          </div>
      )}
      {LoginStore.showOptOutErrorModal && (
        <ModalV2
          variant={variants?.layoutOptOutError}
          styles='modalOptOut'
          closeHandler={() => {
            oktaLogout()
            LoginStore.showOptOutErrorModal = false
          }}
        />
      )}
      {LoginStore.showErrorModal && (
        <ModalV2
          variant={variants?.paragraphModal?.layoutError}
          dataTestId='modal-error'
          closeHandler={() => handleLogout()}
        />
      )}

      {LoginStore.showLimitExceededErrorModal && (
        <ModalV2
          variant={variants?.paragraphModal?.layoutRateQuoteLimitExceededError}
          dataTestId='modal-limit-exceeded-error'
          closeHandler={() => {
            LoginStore.showLimitExceededErrorModal = false
            oktaLogout()
          }}
        />
      )}

      {LoginStore.showCountryRestrictionModal && (
        <ModalV2
          variant={variants?.layoutCountryRestrictedError}
          dataTestId='modal-country-restriction'
          styles='RestrictionModal'
          closeHandler={() => handleCountryRedirection()}
        />
      )}

      {LoginStore.showNetworkErrorModal && (
        <ModalV2
          variant={variants?.layoutNetworkError}
          styles='modalNetworkHiccup'
          dataTestId='modal-network-error'
          closeHandler={() => handleNetworkErrorPopup()}
        />
      )}
      {LoginStore.showCompleteProfileEndCard && (
        <ModalV2
          variant={variants?.layoutCompleteProfileEndCard}
          cancelHandler={async () => {
            await fetchPointsBalanceApi()
            LoginStore.showCompleteProfileEndCard = false
          }}
          closeHandler={async () => {
            await fetchPointsBalanceApi()
            LoginStore.showCompleteProfileEndCard = false
          }}
          pageSource='earnPointsBirthdayPromo'
          styles='earnPointsCompleteProfileEndcard'
          okText={completeProfileEndCard.link}
        />
      )}
      {LoginStore.showSignInPopup && (
        <LoginPage
          oktaLabels={oktaLabels}
          selectCause={selectCause}
          confirmationModal={confirmationModalLabels}
          emailVerificationModalContent={emailVerificationModalContent}
          cascadeSampleIdAddToBasketForStage={siteSettings.cascadeSampleIdAddToBasketForStage}
          cascadeSampleIdAddToBasketForProd={siteSettings.cascadeSampleIdAddToBasketForProd}
          campaignSamplesIdCollection={campaignSamplesIdCollection}
          inActiveCampaigns={siteSettings.inActiveCampaigns}
          privacyText={header.privacyText}
        />
      )}

      {LoginStore.showSessionExpiryModal && (
        <SessionExpiry />
      )}

      {header && (
        <header id='header-layout' className={`${twStyles.headerShrinkWrapper} ${className}`}>
          <Header
            {...header}
            oktaLabels={oktaLabels}
            selectCause={selectCause}
            confirmationModal={confirmationModalLabels}
            {...siteSettings}
            emailVerificationModalContent={emailVerificationModalContent}
            messageCollection={messageCollection}
          />
        </header>
      )}
      <main id='content'>
        <div>{children}</div>
      </main>

      {footer && (
        <footer className={className}>
          <div
            className={
              staticRoutes.homepage === router.asPath
                ? twStyles.footerHomeBgWrapper
                : twStyles.footerBgWrapper
            }
          >
            <Footer
              {...footer}
              {...siteSettings}
            />
          </div>
        </footer>
      )}
      <style jsx>{styles}</style>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.oneOfType([PropTypes.object]),
  siteSettings: PropTypes.oneOfType([PropTypes.object]),
  footer: PropTypes.oneOfType([PropTypes.object]),
  brandsDoingGood: PropTypes.instanceOf(Array),
  layoutData: PropTypes.oneOfType([PropTypes.string]),
  className: PropTypes.oneOfType([PropTypes.string]),
  oktaLabels: PropTypes.oneOfType([PropTypes.object]),
  selectCause: PropTypes.oneOfType([PropTypes.object]).isRequired,
  confirmationModalLabels: PropTypes.instanceOf(Object).isRequired,
  headerAlertBoxRightSideContents: PropTypes.bool,
  router: PropTypes.oneOfType([PropTypes.object]).isRequired,
  emailVerificationModalContent: PropTypes.oneOfType([PropTypes.object]),
  messageCollection: PropTypes.oneOfType([PropTypes.object]),
  alertBarCollections: PropTypes.oneOfType([PropTypes.object]),
  errorPage: PropTypes.bool,
}

Layout.defaultProps = {
  siteSettings: null,
  layoutData: null,
  header: null,
  footer: null,
  brandsDoingGood: [],
  className: '',
  oktaLabels: null,
  headerAlertBoxRightSideContents: false,
  emailVerificationModalContent: null,
  messageCollection: null,
  alertBarCollections: null,
  errorPage: false,
}

export default withRouter(observer(Layout))
