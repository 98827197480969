import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import twStyles from './SignIn.tw-styles'
import Icon from '../Icon/Icon'
import { smartPhoneWidth } from '../../constants/screenWidth'
import LoginStore from './Login-store'
import Button from '../Button'
import { SIGNIN } from '../../constants/texts'
import UserMenu from './UserMenu'
import signInStyles from './SignIn.styles'
import { triggerLoggedInUserIconEvent, triggerLoggedOutUserIconEvent } from '../../helpers/gaEvent.helper'
import AimiaStore from '../../containers/AIMIA/aimia.store'
import { handleClick } from '../../helpers/okta.helper'

const SignIn = (props) => {
  const [profileData, setprofileData] = useState(undefined)
  const [showUserMenu, setUserMenu] = useState(false)
  const logInRef = useRef()
  const {
    style,
    userRightMenuCollection,
    pathName,
  } = props
  const twStyle = twStyles[style] ? twStyles[style] : twStyles.default

  const disableScrollIfSmartPhone = (click) => {
    if (typeof document === 'undefined') return
    if (!click) {
      document.body.style.overflow = ''
      return
    }
    if (window.innerWidth < smartPhoneWidth) {
      document.body.style.overflow = 'hidden'
    } else document.body.style.overflow = ''
  }

  const handlePopoverClick = (e) => {
    if (logInRef.current && !logInRef.current.contains(e.target) && pathName !== '/signup/') {
      setUserMenu(false)
    }
  }

  useEffect(() => {
    LoginStore.showSignInPopup = false
    LoginStore.simplifiedSignupFlow = false
    setprofileData(
      LoginStore.profileData
        ? LoginStore.profileData
        : false,
    )
    document.addEventListener('mousedown', (e) => handlePopoverClick(e))
    return () => {
      document.removeEventListener('mousedown', (e) => handlePopoverClick(e))
    }
  }, [AimiaStore.readMessages])
  disableScrollIfSmartPhone(LoginStore.showSignInPopup)
  return (
    <div ref={logInRef}>
      <div>
        {LoginStore.userLoggedIn === true && (
        <Button
          className={twStyle.button}
          handler={() => {
            setUserMenu(true)
            triggerLoggedInUserIconEvent()
          }}
        >
          <div className={twStyle.span}>
            <div className={twStyles.profile}>
              <div className={twStyles.UserSiginSPWrapper}>
                <div className={twStyles.UserSiginSP}>
                  <div className={twStyles.UserSiginSPInner}>
                    {LoginStore.profileData && LoginStore.profileData.name}
                  </div>
                  <Icon name='user' active={(AimiaStore.unReadMessagesLength > 0) || (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('unreadMessageCount') > 0)} />
                </div>
              </div>
            </div>
          </div>
        </Button>
        )}
        {LoginStore.userLoggedIn === false && (
          <Button
            className={twStyle.button}
            aria-label='Profile Icon'
            handler={() => {
              triggerLoggedOutUserIconEvent()
              handleClick(true)
            }}
          >
            <div id='Profile Icon' className={twStyle.span}>
              <div className={twStyle.IconWrapper}>
                <div className={twStyle.userIcon} type='button'>
                  <Icon aria-label='profile' name='profile' />
                  <p>{SIGNIN}</p>
                </div>
              </div>
            </div>
          </Button>
        )}
        {profileData === undefined && (
          <div />
        )}
        <style jsx global>
          {signInStyles}
        </style>
      </div>
      {showUserMenu && (
        <UserMenu
          userRightMenuCollection={userRightMenuCollection}
          pathName={pathName}
        />
      )}
    </div>
  )
}

SignIn.propTypes = {
  style: PropTypes.oneOfType([PropTypes.string]),
  userRightMenuCollection: PropTypes.instanceOf(Object).isRequired,
  pathName: PropTypes.string.isRequired,
}

SignIn.defaultProps = {
  style: null,
}

export default observer(SignIn)
