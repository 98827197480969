export default {
  default: {
    button: 'sign-in-button  focus:outline',
    span: 'sign-in-span w-full md:flex focus:outline-none justify-center',
    IconWrapper: 'md:flex md:w-full font-montserratSemiBold font-semibold focus:outline-none md:flex-row-reverse',
    userIcon: 'userIcon min-w-45 lg:w-12/12 pl-2 md:pl-half md:flex  md:flex-row-reverse md:items-center  pt-1',
    signin: 'relative top-0 md:items-center md:w-full focus:outline-none font-montserratSemiBold font-semibold  text-14 lg:text-sm md:items-center md:pt-2 md:top-0  md:text-right',
  },
  profile: 'flex justify-center items-center text-sm lg:text-base',
  profileactive: 'bg-white absolute sm:w-1/3 sm:top-10 left-auto focus:outline-none md:w-1/6 md:top-23 right-10 lg:right-30 xl:right-2 shadow-2xl  pt-2 px-2 lg:pb-10 pb-2',
  profileinner: 'flex justify-center  p-2 pl-2 text-14',
  profilebutton: 'py-2  font-montserratSemiBold font-semibold text-lg text-formbutton focus:outline-none text-center lg:text-23',
  profilename: 'pr-05  ',
  UserSiginSPWrapper: 'UserSiginSPWrapper ieWrapper arrowSignIn flex  flex-col-reverse items-center text-primaryCta text-14 font-montserratSemiBold font-semibold md:flex-row md:text-14  leading-articalsmall',
  UserSiginSP: 'UserSiginSP flex flex-col-reverse md:flex-row items-center md:justify-end',
  UserSiginSPInner: 'UserSiginSPInner pt-01 md:pr-03 md:z-10 pb-03',
  signinSpinner: 'signinSpinner w-16 h-16 flex justify-center',
}
