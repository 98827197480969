export default {
  errorInRegisterForm: 'Error In Registration Form',
  invalidCredentials: 'Invalid credentials',
  inputsNotValid: 'Input Not Valid',
  accountDeactivated: 'Account Deactivated',
  firstNameInvalid: 'First Name is invalid.',
  lastNameInvalid: 'Last Name is invalid.',
  emailNotValid: 'Email Not Valid',
  emailNotValidForgotPassword: 'Please provide a valid email address',
  passwordIsTooShort: 'Password Is Too Short',
  currentPasswordIsTooShort: 'Current Password Is Too Short',
  newPasswordIsTooShort: 'New Password Is Too Short',
  confirmPasswordIsTooShort: 'Confirm Password Is Too Short',
  passwordMustContainUppercaseLetters:
    'Password Must Contain Uppercase Letters',
  passwordMustContainLowercaseLetters:
    'Password Must Contain Lowercase Letters',
  passwordMustContainDigits: 'Password Must Contain Digits',
  passwordMustContainSpecailCharacters: 'Must contain at least one of the following &nbsp;! @ $ % &amp; ? *',
  passwordInvalid: 'The password must be minimum 8 characters, including at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character.',
  invalidcredentials: 'Invalid credentials',
  inputsnotvalid: 'Inputs not valid',
  errorinLoginForm: 'Error in Login Form',
  firstNameIsRequired: 'First Name is required.',
  lastNameIsRequired: 'Last Name is required.',
  genderError: 'Please tell us your gender to earn 25 bonus points.',
  dobError: 'Please tell us your full birthday to earn 25 bonus points.',
  dobGenderError: 'Please tell us your {text} to earn 25 bonus points.',
  loginRetryLimitExceeded: 'Too many login attempts. Please try again later.',
  emailDoesNotExists: 'We could not find an account with that email address.',
  genericErrorInForm: 'OOPS! Something went wrong. Please try again',
  emailAlreadyExists: 'We’re sorry, but this email cannot be registered. If you registered previously please [Log In](login) or [Reset Password](/forgot-password). ',
  underAge: 'We\'re sorry, but we can\'t accept your messages or personal information because you do not meet our eligibility requirements.',
  emailAlreadyExistsReward: 'An account with this email already exists.',
  zipCodeNotValid: 'Zip Code Not Valid',
  zipCodeIsRequired: 'Please tell us your zip code to earn 25 bonus points.',
  provideAddress: 'Please provide your address details',
  addressError: 'Please provide your address details',
  postalCodeIsRequired: 'Please provide your postal code',
  cityRequired: 'Please enter your city',
  provinceRequired: 'Please select your province',
  noExtraSpacesAllowed: 'Extra whitespace is not allowed',
  noExtraSpacesAllowedInName: 'Extra whitespace is not allowed',
  checkSpecialCharacter: 'Special Character not allowed',
  mandatoryField: 'This is a mandatory field.',
  tryNewPassword: "Please choose a new password that you haven't used before.",
  invalidEmailDomain: 'We’re sorry, we are unable to register the email address provided.',
  rateOrQuotaLimitExceeded: 'We’re really popular today and things are running a little slow. Please try again later.',
  forgotPasswordRateOrQuotaLimitExceeded: 'Hey there! We’ve sent you email with instructions on resetting your password, please check your inbox. Make sure to check your spam folder!',
  passwordMustBeEqual: 'Passwords don’t match.',
  passwordNoUserInfoError: 'Password should not contain user information',
}
