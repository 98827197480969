export default {

  headerMainWrapper: 'headerWrapper  flex flex-wrap justify-between bg-white items-center shadow-headernav lg:shadow-none w-full relative z50 z-20 xl:z-auto',
  headerWrapper: ' flex flex-wrap justify-between bg-white items-center  h-20 shadow-headernav xl:shadow-none pl-25 pr-10 sm:h-70 xl:h-100 sm:w-full xl:flex xxl:px-20 lg:pl-55 lg:pr-30 xl:px-55 font-montserratlight font-light',
  headerLogo: 'headerLogo',
  logoWrapper: 'w-12/12 md:w-12/12 logo-wraper relative xl:z-1 md:left-auto xl:pr-4 focus:outline',
  dropdownList: 'dropdownList',
  dropdownListSideNav: 'dropdownList-old ',
  threeDots: 'threeDots threeDotsFocus relative flex items-center h-0 xl:h-99 md:w-60 xl:w-80',
  threeDotsDropdown: 'threeDotsDropdown relative md:text-16 lg:p-0 lg:pr-08 hidden xl:block focus:outline',
  arrowIconThreeDots: 'w-15 ml-10px arrowDown cursor-pointer lg:ml-0 -mt-4px px-01 flex items-center hidden xl:block',
  hamburgerButton: 'hamburger-menu-button hover:text-blue-600 w-2/12 bold pl-10 pr-13 relative left:-15  relative xl:hidden',
  primaryNav: 'primary-nav-div primary-nav-dropdown h-full bg-white items-center xl:mr-0 xl:-ml-0 xl:-ml-0 md:ml-0 lg:ml-0 xl:ml-0 lg:mr-0 xl:mr-0  sm:relative xl:flex',
  navMenu: 'hidden xl:block xl:relative nav-menu lg:pl-10 xl:mx-15px xl:pl-0',
  primaryNavItem: 'primary-nav-item-div primary-nav-item-dropdown relative z-1 bg-white px-06 py-2 text-16 leadinf-lightmedium md:ml-6px lg:leading-ultralight lg:px-12 xl:px-15 font-montserratlight font-normal text-formtext focus:outline font-light lg:ml-10px xl:ml-15px xxl:ml-30 lg:ml-0rem',
  primaryNavList: 'lg:relative primaryNavList lg:z-1 lg:bg-white first:shadow-navigation first:pt-10 last:pb-15',
  userManagement: 'user-management-div md:hover:border-b-2 lg:pr-0 lg:w-1/12 xl:w-12/12 sm:px-0 w-12/12 pr-1 sm:pr-0 md:justify-end text-14 lg:text-sm leading-articalsmall  md:flex md:flex-flow-reverse ',
  hamburger: 'hamburger-menu-div w-full z-20 fonts-montserratlight flex bg-hambergerbg h-100v md:h-auto fixed md:absolute left-0 md:left-auto top-0 md:top-10 lg:-top-6 lg:w-23per md:pt-0  md:bg-white md:w-6/12 lg:w-w230 md:shadow-lg md:h-0',
  searchWrapper: 'w-auto min-w-30 min-h-29 relative hidden searchWrapper mr-10px xl:flex items-center bg-white xl:left-0 xxl:left-0',
  searchField: 'md:border-b-0 w-full bg-white md:pl-0 text-primary text-14 border-sign-head focus:outline-none pb-08 px-12',
  searchFieldWrapper: 'relative searchFieldWrapper -left-30',
  searchDropdown: 'absolute right-0 bg-white text-14 leading-sitemap32 font-montserratlight font-light md:pt-07 pl-0 w-260 top-65 left-0 z-2 xl:z-static xl:top-40 md:w-240 xxl:w-330 xl:text-16 md:pt-10',
  searchDropdownList: 'w-full searchDropdownList px-5 pb-12 pt-12 md:pl-30 md:pr-10 leading-leading20 block',
  arrowIcon: 'w-15 ml-10px lg:ml-0 mt-5px lg:mt-8px flex items-center lg:hidden',
  arrowNavDown: 'w-15 ml-10px lg:ml-0 mt-5px lg:mt-8px flex items-center lg:hidden',
  hamburgerMenu: {
    button: 'hamburger-close-menu fill-current text-formtext hover:text-blue-600 -m-1 px-5 h-6 w-1/12 md:hidden',
    dots: 'hamburger-dots hidden md:block px-5',
    wrapper: ' hamburger-wrapper relative overflow-y-auto md:overflow-hidden z-20 py-5 bg-white absolute top-0 pt-8 md:pb-20  left-0 shadow-2xl  min-h-full md:h-auto outline-none h-16 w-10/12 md:w-full',
    wrapper1: 'hambergerTextWrapper px-0',
    nav: {
      mainWrapper: 'fixed xl:static h-screen w-full z-1 xl:z-auto top-0 left-0 xl:h-auto xl:w-auto bg-alpha-black-50 ',
      mainWrapperClose: 'fixed cursor-default xl:hidden xl:static h-screen w-full z-0 xl:z-auto top-0 left-0 xl:h-auto xl:w-auto ',
      wrapper: 'hamburger-nav-wrapper-SP hamburger-wrapper overflow-y-scroll xl:overflow-y-hidden fixed xl:absolute h-screen xl:h-auto xl:min-h-110 pl-25 xl:pl-0 pt-30 xl:pt-0 bg-white w-320 top-0 left-0 xl:w-170 xl:top-67 text-16 leading-lightmedium xl:leading-leading19 pb-12px xl:pb-0 text-formtext border-input-br',
      item: 'hamburger-nav-div-item-SP block text-left font-normal xl:hover:text-white xl:hover:bg-primary w-full pl-0 xl:pl-15 pt-2 font-light outline pb-2 text-16 leading-lightmedium xl:text-14 xl:leading-seller18',
      closeIcon: 'close-icon cursor-pointer w-20 xl:hidden',
    },
    refferal: {
      mainWrapper: 'w-full pt-25 block md:hidden ',
      wrapper: 'w-full refferal-wrapper bg-receiptFeedback px-20 text-center absolute bottom-0 pb-90',
      iconWrapper: 'flex flex-wrap justify-center pt-35 pb-20',
      textWrapper: 'refferal-text-wrapper text-white font-montserratSemiBold font-semibold text-16 leading-lightmedium',
      linkText: 'w-full pt-10 pb-20 text-white underline font-montserratSemiBold font-semibold text-14 text-center',
      linkBtnWrapper: 'w-full pt-20',
      linkBtn: 'btn btn-primaryReceipt-white',
    },
    quickLinks: {
      wrapper: 'quickLinks-wrapper border-input-br ml-0  mr-8 w-10/12  pt-14 md:pt-10px md:w-11/12 lg:w-11/12  pt-2 font-hairline text-sm pl-5',
      item: 'side-nav-quicklink-div-item text-formtext items-center flex block hover:text-blue-600 px-0 pt-2 pb-1 pr-20 leading-lightmedium md:leading-leading20  focus:outline',
    },
  },
  headerscan: 'headerscan scan-receipt-head container mx-auto flex w-full md:top-220 lg:top-250 xl:top-200 md:pt-28 focus:outline-none absolute justify-end items-center',
  aboutHomeHeaderScan: 'headerscan aboutHomeHeaderScan scan-receipt-head container mx-auto flex w-full md:top-220 lg:top-250 xl:top-190 focus:outline-none absolute  justify-end items-center',
  headerScanWithAlert: 'headerscan scan-receipt-head container mx-auto flex w-full  md:pt-200 focus:outline-none absolute justify-end items-center',
  headerAlert: {
    wrapper: 'w-full bg-receiptFeedback headerAlert-IE lg:min-h-52 min-h-38 flex mt-0 flex-wrap items-center font-montserratSemiBold font-semibold text-white',
    logoutWrapper: 'w-full flex flex-wrap login-alert-wrapper tracking-tab lg:tracking-2 lg:text-18 text-14 font-montserratBold font-bold px-20 lg:leading-lightmedium lg:pl-62 lg:pr-57',
    loginWrapper: 'w-full flex flex-wrap ',
    nameWrapperWithIcons: 'widthSixty md:w-8/12 lg:w-9/12 xl:w-9/12 flex items-center flex-wrap',
    nameWrapper: 'w-full pr-10 flex items-center flex-wrap',
    signInButton: 'signin-button underline pr-05 tracking-tab lg:tracking-2 focus:border focus:border-white',
    pointsWrapper: {
      pwrapper: 'pointsWrapper widthFourty md:w-4/12 lg:w-3/12 xl:w-3/12 flex flex-wrap justify-end items-center',
      wrapper: 'w-full flex justify-end',
      rewardPoints: 'flex flex-wrap pl-05 lg:pr-20 items-center rewardsPoints text-14 lg:text-17 xl:text-18 lg:tracking-2 tracking-1',
      charityPoints: 'flex flex-wrap pl-05 lg:pl-10 items-center charityPoints text-14 lg:text-17 xl:text-18 lg:tracking-2 tracking-1',
    },
  },
  alerbox: {
    alertWrapper: 'w-full flex flex-wrap login-alert-wrapper  pl-20 lg:leading-lightmedium lg:pl-62 lg:pr-57',
  },
  desktopView: ' hidden xl:block',
  mobileView: 'block xl:hidden',
}
