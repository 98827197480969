module.exports = {
  mobileBreakpoint: 768,
  tabBreakpoint: 1024,
  desktopBreakPoint: 1440,
  smallBreakpoint: 580,
  deviceWidth: {
    largeScreenStart: 1280,
    tabWidthStart: 768,
    tabWidthEnd: 1279,
    smallScreenEnd: 767,
  },
  homeCarousolSize: {
    deskW: 720,
    deskH: 443,
    mobH: 60,
    mobW: 100,
    tabH: 70,
    tabW: 120,
  },
}
