const getActiveStatus = (currentPath, navPath, name) => {
  const splitedCurrentPath = currentPath?.split('/')
  const splitedNavPath = navPath?.split('/')
  if (name === 'Samples' || name === 'SamplesIcon') {
    return splitedCurrentPath[1] === splitedNavPath[1]
    && splitedCurrentPath[2] !== '' && splitedNavPath[2] !== ''
    && splitedCurrentPath[2] === splitedNavPath[2]
  }
  if (name === 'Rewards' || name === 'points') {
    return ((splitedCurrentPath[1] === 'rewards' && splitedCurrentPath[2] === '') || splitedCurrentPath[1] === 'rewards-intro')
  }
  if (!navPath) return false
  return navPath[0] === '/' ? splitedNavPath[1] === splitedCurrentPath[1] : splitedNavPath[0] === splitedCurrentPath[1]
}

export default getActiveStatus
