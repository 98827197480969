module.exports = {
  coupon24Link: {
    appstore: 'https://apps.apple.com/us/app/coupon24-coupons-from-brands/id1615797330',
    playStore: 'https://play.google.com/store/apps/details?id=com.sigmaledger.cube.wallet&pli=1',
  },
  toolTipContent: {
    coupon24: 'Download and connect your phone number to the Coupon24 app to link your account and make this your default app.',
    linked: 'Linked',
    unLinked: 'Unlinked',
  },
  alreadyLinked: 'YOU ARE LINKED!',
  linkCoupon24: 'LINK MY COUPON24 ACCOUNT',
  COUPONS24: 'COUPONS24',
  coupon24Domain: 'sigmaledger.com',
  gaEventLabel: {
    COUPON24: 'COUPON24',
  },
  apiResponse: {
    WALLET_NOT_FOUND: 'WALLET_NOT_FOUND',
    UNLINKED: 'Unlinked',
    LINKED: 'LINKED',
    SUCCESS: 'success',
    FAILED: 'failed',
    PARTIAL_SUCCESS: 'PARTIAL SUCCESS',
    INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
    NETWORK_ERROR: 'NETWORK_ERROR',
    INVALID_OTP: 'INVALID_OTP',
    PHONE_NUMBER_ALREADY_REGISTERED: 'PHONE_NUMBER_ALREADY_REGISTERED',
    WALLET_INSTALLATION_REQUIRED: 'WALLET_INSTALLATION_REQUIRED',
  },
  sessionExpiry: {
    remainingSeconds: 300,
    remainingTime: '05:00',
  },
}
