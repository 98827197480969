import dayjs from 'dayjs'
import { formatDateWithHyphen } from '../../ReceiptScan/DatePicker/date.helper'

export const postPromotionMessages = (
  messages,
  monthlyPromoRewardId,
  featuredSurveyRewardType,
  messageCollection = [],
) => {
  const postPromotionTransactionalMessage = []
  const postPromotionMessagesList = messages
    && messages.filter((sweeps) => [
      'Internal_3_Fandango',
      'Internal_3_egiftcard',
      featuredSurveyRewardType !== 'Sweeps' && monthlyPromoRewardId,
    ].includes(sweeps.messageId))

  if (postPromotionMessagesList?.length > 0) {
    messageCollection?.map((message) => {
      if (message) {
        const transactionalMessage = postPromotionMessagesList
          .filter((like) => like.messageId === message.aimiaReferenceId)
        if (transactionalMessage.length > 0) {
          if (message?.messageStartAndEndDate === null) {
            return postPromotionTransactionalMessage.push(transactionalMessage[0])
          }
          const presentDate = new Date(formatDateWithHyphen(new Date()))
          const messageStartDate = new Date(formatDateWithHyphen(
            message?.messageStartAndEndDate?.startDate,
          ))
          const messageEndDate = new Date(formatDateWithHyphen(
            message?.messageStartAndEndDate?.endDate,
          ))
          if (presentDate >= messageStartDate && presentDate <= messageEndDate) {
            return postPromotionTransactionalMessage.push(transactionalMessage[0])
          }
        }
      }
      return message
    })
  }
  return postPromotionTransactionalMessage
}

export const readMessages = (allMessages) => {
  const readMessagesList = allMessages && allMessages.filter((item) => item.read && item)
  return readMessagesList
}

export const messageLength = (
  postPromotionMessage,
  messages,
  readMessagesList,
  monthlyPromoRewardId,
  featuredSurveyRewardType,
) => {
  const promotionMessagesList = postPromotionMessages(
    postPromotionMessage,
    monthlyPromoRewardId,
    featuredSurveyRewardType,
  )
  const allMessages = promotionMessagesList
    ? messages?.concat(promotionMessagesList)
    : promotionMessagesList
  const activeMessages = readMessages(allMessages, readMessagesList)
  const messagesLength = allMessages && allMessages.length - activeMessages && activeMessages.length
  return messagesLength
}

export const getDate = (messageDate, pampersDate) => {
  let date
  const newFormattedMessageDate = dayjs(messageDate).format()
  const newFormattedPampersDate = dayjs(pampersDate).format()
  const diff = new Date()
    - (new Date(newFormattedMessageDate) || new Date(newFormattedPampersDate))
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  if (diff === 0) {
    const CurrentDate = `${new Date().getUTCHours()
        - new Date(newFormattedPampersDate).getHours()} hr`
      || `${new Date().getHours()
        - new Date(newFormattedMessageDate).getHours()} hr`
    if (CurrentDate === '0 hr') date = 'now'
    else date = CurrentDate
  } else {
    date = `${monthNames[
      new Date(newFormattedMessageDate).getMonth()
        || new Date(newFormattedPampersDate).getMonth()
    ].substring(0, 3)} ${new Date(newFormattedMessageDate).getDate()
      || new Date(newFormattedPampersDate).getDate()}`
  }
  return date
}

export const formatDate = (date) => {
  const d = new Date(date)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return [month, day, year].join('/')
}
