import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import ModalV2 from '../Modal/ModalV2'
import Icon from '../Icon/Icon'
import LinkButton from '../LinkButton/LinkButton'
import { sessionClearing, securedPages, oktaLogout } from '../../okta/forms/okta.helpers'
import LoginStore from './Login-store'
import styles from './UserMenu.tw-styles'
import staticRoutes from '../../../config/router.config'
import AimiaStore from '../../containers/AIMIA/aimia.store'
import UserMenuStyles from './UserMenu.styles'
import { checkRewardStatus, handleBeforeUnload } from '../../helpers/login.helper'
import { variants } from '../../constants/variants'

const UserMenu = ({
  userRightMenuCollection,
}) => {
  const [showSignOutModal, setShowSignOutModal] = useState(false)
  const pathURL = window?.location?.pathname

  const handleLogout = async () => {
    LoginStore.checkLoginStatus = false
    await sessionClearing(true)
    setShowSignOutModal(true)
    checkRewardStatus()
  }

  const handleSignOutModalClose = () => {
    oktaLogout()
  }
  return (
    <>
      {showSignOutModal && (
        <ModalV2
          variant={variants?.paragraphModal?.usermenuLogout}
          dataTestId='modal-signout'
          redirectUrl={securedPages().indexOf(pathURL) >= 0 ? staticRoutes.homepage : pathURL}
          closeHandler={() => handleSignOutModalClose()}
        />
      )}
      <div className={styles.userMenuWrapper}>
        <div className={styles.userinnerWrapper}>
          <div className={styles.userSp}>
            <div className={styles.userSpText}>
              {LoginStore?.profileData?.name}
            </div>
            <Icon name='user' active={typeof sessionStorage !== 'undefined' && sessionStorage.getItem('unreadMessageCount') > 0} />
          </div>
        </div>
        <div className={styles.userRightMenuWrapper}>
          {(typeof sessionStorage !== 'undefined' && sessionStorage.getItem('unreadMessageCount')) || AimiaStore.messagesLoading !== undefined
            ? (userRightMenuCollection
          && userRightMenuCollection.items
             && userRightMenuCollection.items.map((item) => (
               <LinkButton
                 className={`userMenuText block w-full text-left px-28 outline-none ${item.link === '/my-messages' && typeof sessionStorage !== 'undefined' && sessionStorage.getItem('unreadMessageCount') > 0 && 'unreadMessage relative'}`}
                 key={item.sys && item.sys.id}
                 {...item}
                 linkText={item.linkText.replace('{count}', (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('unreadMessageCount')) || AimiaStore.messagesLoading)}
                 handler={() => {
                   window.removeEventListener('beforeunload', handleBeforeUnload)
                   if (item.name === 'Sign Out') handleLogout()
                 }}
               />
             )))
            : <div className='w-full py-05 flex justify-center'><Icon className='w-16' name='spinnerCoral' /></div> }
        </div>
        <style jsx global>
          {UserMenuStyles}
        </style>
      </div>
    </>
  )
}

UserMenu.propTypes = {
  userRightMenuCollection: PropTypes.instanceOf(Object).isRequired,
  oktaLabels: PropTypes.instanceOf(Object).isRequired,
}

export default observer(UserMenu)

