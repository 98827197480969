module.exports = {
  layoutAlertWrapper: 'layoutAlertWrapper hidden layoutAlert mt-70px md:mt-100px  relative btn-alert  flex bg-gray w-full',
  layoutOuterWrapper: 'overflow-receipt segmanta-overlap overflow-x-hidden background-color',
  headerShrinkWrapper: 'header-shrink-wrapper xl:w-full xl:bg-white xl:z-20 shadow-headernav xl:relative',
  layoutAlerExternaltWrapper: 'Alert bg-bgcolor w-full relative z-20',
  footerBgWrapper: 'bg-bgcolor w-full relative pt-20 pb-06 xl:pb-0 footerBgWrapper',
  footerHomeBgWrapper: 'footerHomeBgWrapper bg-bgcolor w-full relative',
  restrictedText: 'pb-05 restrictedText',
  restrictedTitle: 'text-22 font-semibold pb-10 leading-leading28',
  completeProfile: {
    wrapper: 'px-10 pb-10 pt-05',
    title: 'completeProfile-title font-montserratSemiBold font-semibold pb-12 text-24 xl:text-32 leading-widest xl:leading-leading42',
    subTitle: 'font-montserratlight font-light text-16 xl:text-18 leading-lightmedium xl:leading-widest',
    genderWrapper: 'w-full pt-05 md:w-10/12 xl:w-full mx-auto',
  },
  completeProfileEndCard: {
    birthdayIconWrapper: 'w-full flex flex-wrap justify-center items-center',
    iconWrapper: 'w-150 h-150 md:w-250i md:h-250',
    headingWrapper: ' pb-10 md:pb-25 pt-20 xl:pt-25 font-bold text-32 leading-wider lg:text-42 lg:leading-wider',
    contentWrapper: 'completeContent mx-auto lg:w-780 py-10 text-16 lg:text-22 font-normal leading-lightmedium lg:leading-leading33',
  },
  auth0Loading: 'auth0Loading fixed text-white h-full w-full grid place-items-center left-0 top-0 z-80',
}
